import React from 'react'
const Certificate = () => {
  return (
    <section className="contact">
      <h3 className="contact-title">Certificate</h3>
      <ul>
        <li>
          <a
            href={'https://developers.google.com/widevine/drm/overview'}
            target="_blank"
          >
            <img
              src={'/wv.icon.64x64_72.png'}
              style={{
                width: '24px',
                height: '24px',
                display: 'block',
                margin: '0 auto',
              }}
            />
          </a>
        </li>
        <li>
          <a
              href={
                'https://www.credly.com/badges/fcd5e0eb-0e6a-4f02-9e3c-f570357e5867/public_url'
              }
              target="_blank"
          >
            <img
                src={'/akamai-web-performance-and-offload.png'}
                style={{
                  width: '24px',
                  height: '24px',
                  display: 'block',
                  margin: '0 auto',
                }}
            />
          </a>
        </li>
      </ul>
    </section>
  )
}

export default Certificate
