import React from 'react'
import NavLink from './NavLink'

const TopNavBar = () => {
  return (
    <nav>
      <ul className="nav-container">
        {/* <li className="nav-item">
          <NavLink to="/about">about</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/search">search</NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink to="/tags">tags</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/">posts</NavLink>
        </li>
        <li className="nav-item-hidden">
          <NavLink to="/category">category</NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default TopNavBar
