import React from 'react'

const Contact = ({ siteMetadata }) => {
  return (
    <section className="contact">
      {/* <h3 className="contact-title">Contact me</h3> */}
      <ul>
        {siteMetadata.social.github && (
          <li>
            <a
              href={`https://github.com/${siteMetadata.social.github}`}
              //   target="_blank"
            >
              <i className="fa fa-github" aria-hidden="true" />
            </a>
          </li>
        )}
        {siteMetadata.social.twitter && (
          <li>
            <a
              href={`https://twitter.com/${siteMetadata.social.twitter}`}
              //   target="_blank"
            >
              <i className="fa fa-twitter" aria-hidden="true" />
            </a>
          </li>
        )}
        {siteMetadata.social.stackoverflow && (
          <li>
            <a
              href={`mailto:${siteMetadata.social.stackoverflow}`}
              //   target="_blank"
            >
              <i className="fa fa-stack-overflow" aria-hidden="true" />
            </a>
          </li>
        )}
        {siteMetadata.social.email && (
          <li>
            <a href={`mailto:${siteMetadata.social.email}`}>
              <i className="fa fa-envelope-o" aria-hidden="true" />
            </a>
          </li>
        )}
        <li>
          <a href={'https://www.buymeacoffee.com/seunghee'} target="_blank">
            <img
              src={'/buy-me-a-coffee.png'}
              style={{
                width: '18px',
                height: '18px',
                display: 'block',
                margin: '0 auto',
              }}
            />
          </a>
        </li>
      </ul>
    </section>
  )
}

export default Contact
