import React from 'react'
import { Link } from 'gatsby'

const NavLink = ({ to, children }) => (
  <Link activeClassName="nav-item-active" to={to}>
    {children}
  </Link>
)

export default NavLink
