import React from 'react'
import { Link } from 'gatsby'
import { kebabCase } from 'lodash'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const Category = ({ mainCategoryGroup, subCategoryGroup }) => {
  const categories = {}
  mainCategoryGroup.forEach((category) => {
    categories[category.mainCategory.replace(/\s/g, '_')] = []
  })
  subCategoryGroup.forEach((category) => {
    categories[category.nodes[0].frontmatter.category.replace(/\s/g, '_')].push(
      category.subCategory,
    )
  })
  const uuids = []
  Object.entries(categories).map(([key, value]) => {
    uuids.push(key)
    return uuids
  })
  return (
    <section className="contact">
      <h3 className="contact-title">Category</h3>
      <Accordion
        allowMultipleExpanded="true"
        allowZeroExpanded="true"
        preExpanded={uuids}
      >
        {Object.entries(categories).map(([key, value]) => (
          <AccordionItem key={key} uuid={key}>
            <AccordionItemHeading>
              <AccordionItemButton className="main-menu">
                <i className="fa fa-tags" aria-hidden="true" />
                &nbsp;&nbsp;{key}
              </AccordionItemButton>
            </AccordionItemHeading>
            {value.map((val) => (
              <AccordionItemPanel className="sub-menu" key={val}>
                &nbsp;.&nbsp;
                <Link to={`/${kebabCase(key)}/${kebabCase(val)}/`}>{val}</Link>
              </AccordionItemPanel>
            ))}
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  )
}

export default Category
