import React from 'react'

import Category from './Category'
import Contact from './Contact'
import Certificate from './Certificate'

const SideBar = ({ siteMetadata, mainCategoryGroup, subCategoryGroup }) => (
  <aside className="sidebar">
    <header>
      <div className="about">
        {/*<Link to="/">*/}
        {/*  <div className="author-name">{siteMetadata.author}</div>*/}
        {/*</Link>*/}
        <p>{siteMetadata.description}</p>
        <br />
        <Contact siteMetadata={siteMetadata}></Contact>
      </div>

      <div className="content-menu">
        <Certificate />
        <Category
          mainCategoryGroup={mainCategoryGroup}
          subCategoryGroup={subCategoryGroup}
        ></Category>
      </div>
    </header>
    <footer>
      <div className="copyright">
        <font size={'5'}>&copy;</font> {siteMetadata.author}. all rights
        reserved.
      </div>
    </footer>
  </aside>
)

export default SideBar
