import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import '../../styles/fonts/font-awesome/css/font-awesome.min.css'
import '../../styles/styles.scss'
import 'katex/dist/katex.min.css'
import TopNavBar from '../TopNavBar'
import SideBar from '../SideBar'
import Helmet from 'react-helmet'

const BlogLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            author
            description
            social {
              github
              twitter
              stackoverflow
              email
            }
          }
        }
        mainCategoryGroup: allMarkdownRemark(limit: 2000) {
          group(field: frontmatter___category) {
            mainCategory: fieldValue
          }
        }
        subCategoryGroup: allMarkdownRemark(limit: 2000) {
          group(field: frontmatter___subCategory) {
            subCategory: fieldValue
            nodes {
              frontmatter {
                category
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="wrapper">
        <Helmet>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2695370852236805"
            crossOrigin="anonymous"
          />
        </Helmet>
        <header>
          <TopNavBar />
        </header>
        <main>
          {/* side-content */}
          <SideBar
            siteMetadata={data.site.siteMetadata}
            mainCategoryGroup={data.mainCategoryGroup.group}
            subCategoryGroup={data.subCategoryGroup.group}
          />
          {/* main-content */}
          <div className="content-box clearfix">{children}</div>
        </main>
        <footer></footer>
      </div>
    )}
  />
)

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlogLayout
